import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown, faQuestion, faLayerGroup, faArrowLeft,
} from '@fortawesome/pro-solid-svg-icons';
import MapsDropdown from '../MapsDropdown/MapsDropdown';
import './Header.scss';

const titles = {
  english: 'Haitian Revolution',
  french: 'La Révolution Haïtienne',
  spanish: 'La Revoluciôn Haitiana',
  kreyol: 'Revolisyon Ayiti',
};

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.dropdownRef = React.createRef();
    this.dropdownTimer = null;
    this.dropdownClick = this.dropdownClick.bind(this);
    this.dropdownMouseOut = this.dropdownMouseOut.bind(this);
    this.dropdownMouseOver = this.dropdownMouseOver.bind(this);
  }

  getDesktopButton() {
    const {
      currentMap,
      legendOpen,
    } = this.props;
    if (legendOpen) return null;

    const currentEra = currentMap === null
      ? ''
      : ` ${currentMap.yearStart} - ${currentMap.yearEnd}`;


    return (
      <div
        className="header__maps"
        onClick={this.dropdownClick}
        onMouseOver={this.dropdownMouseOver}
        onFocus={this.dropdownMouseOver}
        onMouseOut={this.dropdownMouseOut}
        onBlur={this.dropdownMouseOut}
        ref={this.dropdownRef}
      >
        Jump to
        {currentEra}
        <FontAwesomeIcon
          className="header__maps-icon"
          icon={faCaretDown}
        />
      </div>
    );
  }

  getMobileButton() {
    return (
      <div
        className="header__maps"
        onClick={this.dropdownClick}
      >
        <FontAwesomeIcon
          icon={faLayerGroup}
        />
      </div>
    );
  }

  getMapsButton() {
    // const { mobile } = this.props;
    // if (mobile) {
    //   return this.getMobileButton();
    // }
    return this.getDesktopButton();
  }

  getMapsDropdownList() {
    const {
      currentMap,
      setCurrentMap,
      maps,
      mobile,
      dropdownOpen,
    } = this.props;
    if (!dropdownOpen) return <div />;
    return (
      <MapsDropdown
        mobile={mobile}
        maps={maps}
        currentMap={currentMap}
        buttonNode={this.dropdownRef}
        setCurrentMap={setCurrentMap}
        mouseOver={this.dropdownMouseOver}
        mouseOut={this.dropdownMouseOut}
      />
    );
  }

  getAboutContent() {
    const { mobile } = this.props;
    if (mobile) {
      return <FontAwesomeIcon icon={faQuestion} />;
    }
    return (
      <div>
        <FontAwesomeIcon icon={faQuestion} />
        <span className="header__about-text">
          ABOUT
        </span>
      </div>
    );
  }

  getDropdownBackButton() {
    const {
      legendOpen,
      closeLegend,
      dropdownOpen,
      closeDropdown,
    } = this.props;
    if (!dropdownOpen && !legendOpen) return null;
    const onClick = () => {
      if (dropdownOpen) {
        closeDropdown();
      }
      if (legendOpen) {
        closeLegend();
      }
    };
    return (
      <div className="header__back" onClick={onClick}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </div>
    );
  }

  getAbout() {
    const {
      legendOpen,
      mobileSidebarView,
      mobile,
      dropdownOpen,
    } = this.props;

    if (dropdownOpen || legendOpen) return null;
    if (mobile && mobileSidebarView === 'expanded') return null;

    return (
      <a className="header__about" href={`http://${window.location.hostname.replace('map', 'www')}/about`} target="_blank" rel="noopener noreferrer">
        {this.getAboutContent()}
      </a>
    );
  }


  getHeader() {
    const { mobile } = this.props;
    if (mobile) {
      return this.getMobileHeader();
    }
    return this.getDesktopHeader();
  }

  getDesktopHeader() {
    const { children, lang } = this.props;
    return (
      <div className="header">
        <div className="header__left">
          {this.getMapsButton()}
          {this.getMapsDropdownList()}
          {children}
        </div>
        <div className="header__title">
          {titles[lang] || titles.english}
        </div>
        {this.getAbout()}
      </div>
    );
  }

  getLegendButton() {
    const {
      openLegend,
      legendOpen,
      mobileSidebarView,
      dropdownOpen,
    } = this.props;
    if (dropdownOpen || legendOpen || mobileSidebarView === 'expanded') return null;
    return (
      <div
        className="header__legend"
        onClick={openLegend}
      >
        <FontAwesomeIcon
          icon={faLayerGroup}
        />
      </div>
    );
  }

  getLegendTitle() {
    const { legendOpen } = this.props;
    if (!legendOpen) return null;
    return <div className="header__legend-title">MAP LEGEND</div>;
  }

  getMobileHeader() {
    return (
      <div className="header header--mobile">
        {this.getDropdownBackButton()}
        {this.getLegendButton()}
        {this.getMapsButton()}
        {this.getMapsDropdownList()}
        {this.getLegendTitle()}
        {this.getAbout()}
      </div>
    );
  }

  dropdownClick() {
    const { openDropdown } = this.props;
    openDropdown();
  }

  dropdownMouseOver() {
    const { mobile } = this.props;
    if (mobile) return;
    if (this.dropdownTimer !== null) {
      clearTimeout(this.dropdownTimer);
      this.dropdownTimer = null;
    }
  }

  dropdownMouseOut() {
    const {
      mobile,
      closeDropdown,
    } = this.props;
    if (mobile) return;
    this.dropdownTimer = setTimeout(() => {
      closeDropdown();
      this.dropdownTimer = null;
    }, 400);
  }


  render() {
    return this.getHeader();
  }
}

Header.defaultProps = {
  currentMap: null,
  dropdownRef: null,
  dropdownOpen: false,
};

Header.propTypes = {
  setCurrentMap: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  maps: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentMap: PropTypes.shape({
    actors: PropTypes.array,
    description: PropTypes.string,
    id: PropTypes.string,
    map: PropTypes.number,
    mapCaption: PropTypes.string,
    title: PropTypes.string,
    yearEnd: PropTypes.number,
    yearStart: PropTypes.number,
  }),
  dropdownRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  mobile: PropTypes.bool.isRequired,
  openLegend: PropTypes.func.isRequired,
  closeLegend: PropTypes.func.isRequired,
  legendOpen: PropTypes.bool.isRequired,
  openDropdown: PropTypes.func.isRequired,
  closeDropdown: PropTypes.func.isRequired,
  mobileSidebarView: PropTypes.string.isRequired,
  dropdownOpen: PropTypes.bool,
  lang: PropTypes.string.isRequired,
};

export default Header;
